<template>
  <div>
    <h5 class="p-t-20 text-center bold p-b-10 auth-title">{{ $t('auth.login page') }}</h5>
    <ValidationObserver ref="form_create_entry" v-slot="{ passes, valid, validated }">
      <form novalidate autocomplete="off" @submit.prevent="passes(login)">
        <HeaderNotice ref="notice"></HeaderNotice>
        <AppInput inputStyle="normal" name="login_email" type="email" rules="required"
                  :label="$t('common.mail address')"
                  v-model="email"></AppInput>
        <AppInput inputStyle="normal" name="password" type="password" rules="required" :label="$t('common.password')"
                  v-model="password"></AppInput>
        <div class="text-center">
          <router-link class="forgot-password color-active" :to="{name: forgotPasswordRoute}">パスワードをお忘れの方はこちら
          </router-link>
        </div>
        <div>
          <button class="btn btn-complete btn-auth" type="submit">{{ $t('auth.login') }}</button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import ROUTES from "@consts/routes";
import HeaderNotice from "@components/_common/HeaderNotice";

export default {
  data() {
    let resetPassword = null;
    let forgotPassword = null;
    switch (this.$store.state.setting.authType) {
      case 'adminAuth': {
        resetPassword = ROUTES.ADMIN.AUTH.RESET_PASSWORD
        forgotPassword = ROUTES.ADMIN.AUTH.FORGOT_PASSWORD
        break;
      }
      case 'csAuth': {
        resetPassword = ROUTES.CS.AUTH.RESET_PASSWORD
        forgotPassword = ROUTES.CS.AUTH.FORGOT_PASSWORD
        break;
      }
      case 'hotelAuth': {
        resetPassword = ROUTES.HOTEL.AUTH.RESET_PASSWORD
        forgotPassword = ROUTES.HOTEL.AUTH.FORGOT_PASSWORD
        break;
      }
      case 'taxiAuth': {
        resetPassword = ROUTES.TAXI.AUTH.RESET_PASSWORD
        forgotPassword = ROUTES.TAXI.AUTH.FORGOT_PASSWORD
        break;
      }
    }
    return {
      resetPasswordRoute: resetPassword,
      forgotPasswordRoute: forgotPassword,
      email: this.$route.query.email,
      password: null,
      error: false
    }
  },
  mounted() {
    if (this.$route.query.verified === 'yes') {
      this.$refs.notice.setSuccess(this.$t('auth.verify ok'));
    }
    if (this.$route.query.changePass === 'yes') {
      this.$refs.notice.setSuccess(this.$t('auth.reset pass ok'));
    }
  },
  components: {HeaderNotice},
  methods: {
    async login() {
      let role = null;
      let homeRoute = null;
      switch (this.$store.state.setting.authType) {
        case 'adminAuth': {
          role = this.$consts.ROLES.ROLE_ADMIN_NAME
          homeRoute = ROUTES.ADMIN.DASHBOARD
          break;
        }
        case 'csAuth': {
          role = this.$consts.ROLES.ROLE_CS_NAME
          homeRoute = ROUTES.CS.DASHBOARD
          break;
        }
        case 'hotelAuth': {
          role = this.$consts.ROLES.ROLE_HOTEL_NAME
          homeRoute = ROUTES.HOTEL.DASHBOARD
          break;
        }
        case 'taxiAuth': {
          role = this.$consts.ROLES.ROLE_TAXI_NAME
          homeRoute = ROUTES.TAXI.DASHBOARD
          break;
        }
      }
      const res = await this.$auth.login({
        email: this.email,
        password: this.password,
        role: role
      }, this.$store.state.setting.authType)
      if (!res.hasErrors()) {
        const prevRouteName = this.$store.state.setting.prevRouter.name;
        if (prevRouteName && prevRouteName !== this.resetPasswordRoute && prevRouteName !== this.forgotPasswordRoute) {
          this.$router.push(this.$store.state.setting.prevRouter)
        } else {
          this.$router.pushByName(homeRoute)
        }
      } else {
        this.$refs.notice.setError(res.data.msg ? res.data.msg : this.$t('common.error'));
      }
    },
  }
}
</script>
<style scoped>
.forgot-password {
  text-decoration: underline !important;
}
</style>
